import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 4-4-4-4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`Team Series WOD 5`}</strong></p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`3-Burpee Box Jump Overs (24/20)`}</p>
    <p>{`3-Deadlifts (225/155)`}</p>
    <p>{`6-Burpee Box Jump Overs`}</p>
    <p>{`6-Deadlifts`}</p>
    <p>{`9-Burpee Box Jump Overs`}</p>
    <p>{`9-Deadlifts, etc.`}</p>
    <p><em parentName="p">{`*`}{`*`}{`Do this alone or synchronized with a partner.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids today.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.  More
info to come. Truegritcomp.wordpress.com.***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      